
document.getElementById('btnPlatform').addEventListener('click', function() {
    let newUrl = 'https://app.dunasystem.com:9090';
    window.location.href = newUrl;
});

document.getElementById('btnPlatform2').addEventListener('click', function() {
    let newUrl = 'https://app.dunasystem.com:9090';
    window.location.href = newUrl;
});


document.getElementById('btnWhatsapp').addEventListener('click', function() {
    let newUrl = 'https://wa.me/5585992002562';
    window.location.href = newUrl;
});